import { Auth0Provider } from "@auth0/auth0-react";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
} from "reactfire";
import "./App.css";
import Home from "./Pages/Home";

const firebaseConfig = {
  apiKey: "AIzaSyDFlQ8apBkkuEQDT9DwPiqbpDD6aEuDaDM",
  authDomain: "pond-sg.firebaseapp.com",
  projectId: "pond-sg",
  storageBucket: "pond-sg.appspot.com",
  messagingSenderId: "484472924208",
  appId: "1:484472924208:web:6bf65ecacb38807730e5f5",
  measurementId: "G-7H5DMDBE5N",
};

function App() {
  const app = initializeApp(firebaseConfig);

  const auth = getAuth(app);
  const firestore = getFirestore(app);

  // if (process.env.NODE_ENV === "development") {
  //   connectAuthEmulator(auth, "http://localhost:9099");
  // }

  return (
    <Auth0Provider
      domain="dev-58l13afm.au.auth0.com"
      clientId="ffYatL2Llo7PyGaJ7QqsFr2hfxmkZw3I"
      audience="https://auth.pond.ltd/signIn"
      redirectUri={window.location.origin}
    >
      <FirebaseAppProvider firebaseApp={app}>
        <AuthProvider sdk={auth}>
          <FirestoreProvider sdk={firestore}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
              </Routes>
            </BrowserRouter>
          </FirestoreProvider>
        </AuthProvider>
      </FirebaseAppProvider>
    </Auth0Provider>
  );
}

export default App;
