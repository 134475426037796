import { addDoc, collection } from "firebase/firestore";
import { Field, Form, Formik } from "formik";
import { useFirestore } from "reactfire";

export default function MessageForm() {
  const firestore = useFirestore();

  function handleSubmit({ message }: { message: string }) {
    addDoc(collection(firestore, "messages"), { message })
      .then((reference) => console.log(reference.id))
      .catch((error) => console.error(error.message));
  }

  return (
    <Formik<{ message: string }>
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{ message: "" }}
    >
      <Form>
        <Field name="message" placeholder="Message" />
        <button type="submit">Submit</button>
      </Form>
    </Formik>
  );
}
