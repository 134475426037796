import { useAuth0 } from "@auth0/auth0-react";
import "firebase/auth";
import { signInWithCustomToken } from "firebase/auth";
import { useEffect } from "react";
import { useAuth } from "reactfire";
import MessageForm from "./MessageForm";

export default function Home() {
  const auth0 = useAuth0();
  const auth = useAuth();

  function signIn() {
    auth0.loginWithRedirect();
  }

  function signOut() {
    auth0.logout({
      returnTo: window.location.origin,
    });
  }

  useEffect(() => {
    if (auth0.isAuthenticated) {
      auth0.getAccessTokenSilently().then((token) => {
        fetch("/signIn", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.text())
          .then((text) => {
            signInWithCustomToken(auth, text)
              .then((value) => console.log(value))
              .catch((error) => console.error(error.message));
          })
          .catch((error) => console.error(error.message));
      });
    }
  }, [auth0, auth]);

  if (!auth0.user) {
    return <button onClick={signIn}>Sign In</button>;
  }

  return (
    <div>
      <div>
        <span>{auth0.user.name}</span>&nbsp;
        <button onClick={signOut}>Sign Out</button>
      </div>

      <div>
        <MessageForm />
      </div>
    </div>
  );
}
